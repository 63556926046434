<template>
  <div>
    <b-overlay :show="show" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formHTML">
        <input type="hidden" name="user_id" :value="user.id">
        <!-- media -->
        <b-row>
          <b-col sm="6">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img v-if="optionsLocal.avatar != null" ref="previewEl" rounded
                    :src="(optionsLocal.avatar.startsWith('data') ? '' : $http.defaults.baseURL + 'storage/app/private/') + optionsLocal.avatar"
                    height="80" />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="$refs.refInputEl.$el.click()">
                  Subir
                </b-button>
                <b-form-file ref="refInputEl" v-model="profileFile" name="avatar" accept=".jpg, .png, .gif"
                  :hidden="true" plain @input="inputImageRenderer" />
                <!--/ upload button -->

                <!-- reset -->
                <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75">
                  Borrar
                </b-button>
                <!--/ reset -->
                <b-card-text>Permitidos ficheros JPG, GIF or PNG. Máximo tamaño 800kB</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col sm="6">
            <b-card no-body class="border-success" v-if="subscription">
              <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                <h5 class="mb-0">
                  Plan Actual
                </h5>
                <b-badge variant="light-success" >
                  {{ subscription.type ? 'Premium' : 'Básico' }}
                </b-badge>
                <div class="w-100">
                  <small class="text-muted w-100">Registrado : {{ subscription.date_start ? new
                      Intl.DateTimeFormat('es-ES', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new
                        Date(subscription.date_start)) : ''
                  }}</small> <br>
                  <small class="w-100">Estado : 
                    <span
                      :class="'text-'+status_array[subscription.status].class">{{ status_array[subscription.status].title }}  
                    </span> </small><br>
                  <small class="w-100">Caducidad Metodo de Pago : {{ subscription.renewal_date ? new
                      Intl.DateTimeFormat('es-ES', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new
                        Date(subscription.renewal_date)) :'' }}</small>
                </div>
              </b-card-header>
              <b-card-body>
                <b-alert v-if="!subscription.status && subscription.next_date" class="px-1" variant="warning" show><small>Se ha producido un error en el método de pago. Vuelva a activar la subscripción para corregirlo</small></b-alert>
                <b-alert v-if="subscription.status == 2" class="px-1" variant="danger" show><small>Se ha producido un error en el método de pago. Contacte con nosotros para que podamos ayudarle.</small></b-alert>
                <!-- <b-row v-if="subs_act" class="mt-2 justify-content-center">
                  <b-form-radio v-model="subs_type" value="0" plain>
                    Básico ({{rates.basic}}€ / mes)
                  </b-form-radio>
                  <b-form-radio v-model="subs_type" plain value="1" class="ml-2">
                    Premium {{rates.premium}}€ / mes)
                  </b-form-radio>
                  <b-button class="mt-2 mx-5 w-75" variant="outline-success" @click="pay" block>
                    Pagar
                  </b-button>
                </b-row> -->
                <div v-if="subscription.status != 2  && subscription.status != 3">
                  <b-button class="mt-2" variant="success" block @click="pay" v-if="!subscription.status || subscription.status == 4">Activar Subscripción</b-button>
                  <!-- <b-button class="mt-2" variant="success" block @click="subs_act = !subs_act" v-if="!subscription.status || subscription.status == 4">Activar Subscripción</b-button> -->
                  <!-- <b-button class="mt-2" variant="warning" block @click="update_subscription" v-else>Actualizar Subscripción</b-button> -->
                  <b-button variant="danger" block @click="cancel">Cancelar Subscripción</b-button>
                </div>

                <b-button class="mt-2" variant="warning" block :to="{path:'/contact'}" v-else> Contactar </b-button>
              </b-card-body>
            </b-card>
          </b-col>
          <!--/ media -->
        </b-row>
        <hr>
        <!-- form -->
        <b-row>
          <b-col sm="6">
            <b-form-group label="Nombre" label-for="account-name">
              <b-form-input v-model="optionsLocal.name" placeholder="nombre" name="name" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Apellidos" label-for="account-surname">
              <b-form-input v-model="optionsLocal.surname" name="surname" placeholder="Apellidos" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="NIF" label-for="account-nif">
              <b-form-input v-model="optionsLocal.nif" name="nif" placeholder="NIF" />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Empresa" label-for="account-business_name">
              <b-form-input v-model="optionsLocal.business_name" name="business_name" placeholder="Empresa" />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Direccción" label-for="account-address">
              <b-form-input v-model="optionsLocal.address" name="address" placeholder="Dirección" />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Número" label-for="account-number">
              <b-form-input v-model="optionsLocal.number" name="number" />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="C.P." label-for="account-cp">
              <b-form-input v-model="optionsLocal.cp" name="cp" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Localidad" label-for="account-locality">
              <b-form-input v-model="optionsLocal.locality" name="locality" placeholder="Localidad" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="updateForm">
              Guardar cambios
            </b-button>
            <b-button variant="warning" class="mt-2 mr-1" type="button" link to="/">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormRadio, BOverlay,BCardHeader, BCardBody, BBadge, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapState } from 'vuex';

export default {
  components: {
    BButton,
    BForm,
    BCardHeader,
    BCardBody,
    BBadge,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BRow,
    BOverlay,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  data() {
    return {
      show : false,
      status_array : [
        {class : 'success', title : 'Periodo de Pruebas'},{class : 'success', title : 'Activo'},{class : 'danger', title : 'Suspendido'},
        {class : 'danger', title : 'Cancelado'},{class : 'warning', title : 'Fin Periodo de Pruebas'}
      ],
      profileFile: null,
      subscription: null,
      subs_act: false,
      subs_type: 1,
      rates : {'basic' : 0 , 'premium' : 0}      

    }
  },
  mounted() {
    this.$http.get("/api/getRates").then((response) => {
      this.rates = response.data;
    });
    this.getUser();  

  },
  methods: {
    logout: function () {
      this.$store.dispatch("auth/logout")
      this.$router.push('/login');
    },
   getUser: function () {
      this.show = true;
        this.$http.get("/api/getUser?id=" + this.user.id).then((response) => {
          this.optionsLocal = {
            avatar: response.data['avatar'],
            name: response.data['name'],
            surname: response.data['surname'],
            business_name: response.data['business_name'],
            address: response.data['address'],
            number: response.data['number'],
            cp: response.data['cp'],
            locality: response.data['locality'],
            nif: response.data['nif'],
            email: response.data['email']
          }
          this.subscription = response.data['subscription']
          this.show = false;
        });      
    },
    updateForm: async function (e) {
      /* formData */
      var formData = new FormData(this.$refs.formHTML);
      /* AJAX request */
      await this.$http({
        method: "post",
        url: "/api/updateProfile",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => { window.console.log(response.data); })
        .catch(response => { window.console.log(response) });
      this.$router.push('/');
    },
    pay: function () {
      window.location.href = this.$http.defaults.baseURL + 'api/payment/' + this.user.id + '?type=' + this.subs_type;
    },
    // update_subscription: function () {
    //   let that = this;
    //   let msg = this.subscription.type ? 'BÁSICO' : 'PREMIUM'
    //   this.$bvModal.msgBoxConfirm('Se actualizará su subscripción a ' + msg , { okVariant: 'success', size: 'sm', buttonSize: 'sm', okTitle: 'SI', cancelTitle: 'NO' })
    //     .then(value => {
    //       this.show = true;
    //       let that = this;
    //       if (value) {
    //         this.$http.post("/api/updateSubscription", { id: this.subscription.id }).then((response) => {
    //           if (response.data) {
    //             if(response.data == 1){
    //               that.$bvToast.toast('¡Ya es usuario Premium!', {variant: 'success', title : 'Realizado con Éxito' } );
    //             }else {
    //               that.$bvToast.toast('Su cuenta ha pasado a modo Prueba.', {variant: 'error', title : 'Pago no Realizado' } );
    //             }
    //             that.getUser(); 
    //           }else {
    //             that.$bvToast.toast('El cambio tendrá efecto el próximo mes.', {variant: 'success', title : 'Realizado con Éxito' } );
    //           }
    //           this.show = false;
    //         });
    //       }else{
    //          this.show = false;
    //       }
    //     })
    // },
    cancel: function () {
      this.$bvModal.msgBoxConfirm('¿Quiere tramitar la baja de la subscripción?', { okVariant: 'success', size: 'sm', buttonSize: 'sm', okTitle: 'SI', cancelTitle: 'NO' })
        .then(value => {
          let that = this;
          if (value) {
            this.$http.post("/api/CancelSubscription", { user: this.user.id }).then((response) => {
              if (response.data) {
                that.logout();
              }
            });
          }
        })
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const optionsLocal = ref(
      JSON.parse(
        JSON.stringify({
          avatar: null,
          name: '',
          surname: '',
          business_name: '',
          address: '',
          number: '',
          cp: '',
          locality: '',
          nif: '',
          email: '',
        })
      )
    );

    const { inputImageRenderer } = useInputImageRenderer(refInputEl,

      (base64) => {
        // eslint-disable-next-line no-param-reassign
        optionsLocal.value.avatar = base64;
      }
    )

    return {
      refInputEl,
      previewEl,
      optionsLocal,
      inputImageRenderer,
    }
  },
}
</script>
